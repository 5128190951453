<template>
  <div class="readme-article">
    <h1 id="财务配置">4.教学</h1>
    <h2 id="年级设置">4.1年级设置</h2>
    <p>招生线索年级设置，例如：一年级、二年级、三年级等</p>
    <p>
      <img src="@/assets/img/recruit/config4-1.png" />
    </p>
    <h2 id="学科设置">4.2学科设置</h2>
    <p>学科设置，例如：语文、数学、英语等</p>
    <p>
      <img src="@/assets/img/recruit/config4-2.png" />
    </p>
    <h2 id="教学模式-班型">4.3教学模式(班型)</h2>
    <p>班型设置，例如：一对一、一对多、体验班等</p>
    <p>
      <img src="@/assets/img/recruit/config4-3.png" />
    </p>
    <h2 id="考试类型设置">4.4考试类型设置</h2>
    <p>考试类型设置，例如：月考、期中考、期末考等</p>
    <p>
      <img src="@/assets/img/recruit/config4-4.png" />
    </p>
    <h2 id="节假日调课设置">4.5节假日调课设置</h2>
    <p>节假日调课设置，设置调课日期以及补上星期几的课。</p>
    <p>
      <img src="@/assets/img/recruit/config4-5.png" />
    </p>
  </div>
</template>

<script>
export default {
  name: "Recruit0-4",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>